import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from './navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeDto } from '../data/objects/employee.dto';
import { Title } from '@angular/platform-browser';
import { BasicsRepository } from '../data/repository/basics.repository';
import { SheetRepository } from '../data/repository/sheet.repository';
import { RxjsService } from '../share/services/rxjs/rxjs.service';
import { UrlChangeListener, UrlEvents } from '../events/url.events';
import { Subscription } from 'rxjs';
import {
  NotificationEvents,
  NotificationListener,
  UnseenCountListener
} from '../events/notification.events';
import { NotificationDto } from '../data/objects/notification.dto';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent
  implements OnInit, UrlChangeListener, OnDestroy, NotificationListener, UnseenCountListener
{
  isCollapse = false;
  focusArea: 'space' | 'setting' | 'chat' | 'forms' | 'profile' | string = 'space';

  employee: EmployeeDto = new EmployeeDto();
  chatBadge: number =0;
  isOwner = false;
  subscribes = new Subscription();

  constructor(
    private navService: NavigationService,
    private titleService: Title,
    private baseRepo: BasicsRepository,
    private rxjs: RxjsService,
    private sheetRepo: SheetRepository,
    private route: ActivatedRoute,
    private router: Router,
    private urlEvents: UrlEvents,
    private notifEvents: NotificationEvents,
  ) {
    rxjs.emitUpdateAvatar.subscribe((avatar: any) => {
      this.employee.Icon = avatar;
    });
    this.subscribes.add(this.urlEvents.onUrlChange(this));
    this.subscribes.add(this.notifEvents.subscribeNotification(this));
    this.subscribes.add(this.notifEvents.subscribeUnseenCount(this));
  }

  onUnseenNotifCountChange(count: number): void {
    this.chatBadge = count;
  }

  onNewNotification(notif: NotificationDto): void {
    this.chatBadge++;
  }

  ngOnDestroy(): void {
    this.subscribes.unsubscribe();
  }

  onUrlChange(url: string) {
    this.initFocusArea();
  }

  async ngOnInit() {
    this.isOwner = await this.baseRepo.amIOwner();
    this.initFocusArea();
    await this.navService.getAllSpaces();
    this.employee = await this.navService.getMyEmployee();
    let notifs = await this.baseRepo.getUnseenNotifications();
    if (notifs && notifs.length > 0) {
      this.chatBadge = notifs.length;
    }
  }

  initFocusArea() {
    this.focusArea = this.router.url.split('/')[2];

    if (this.focusArea == 'setting') {
      this.titleService.setTitle('Atwork | Administration');
    } else if (this.focusArea == 'chat') {
      this.titleService.setTitle('Atwork | Messages');
    } else if (this.focusArea == 'forms') {
      this.titleService.setTitle('Atwork | Forms');
    } else if (this.focusArea == 'space') {
    }
  }

  onToggleCollapseClick() {
    this.isCollapse = !this.isCollapse;
  }

  backToSpace() {
    this.focusArea = 'space';
    if (this.navService.currentSpaceId == undefined){
      this.navService.goToDefaultSpace();
    }else
    this.navService.goToCurrentSpace();
  }

  messages() {
    this.focusArea = 'chat';
    this.titleService.setTitle('Atwork | Messages');
    this.router.navigateByUrl('/panel/chat');
  }

  profile() {
    this.focusArea = 'profile';
    this.titleService.setTitle('Atwork | Profile');
    this.router.navigateByUrl('/panel/profile');
  }

  administration() {
    this.focusArea = 'setting';
    this.titleService.setTitle('Atwork | Administration');
    this.router.navigateByUrl('/panel/setting');
  }

  forms() {
    this.focusArea = 'forms';
    this.titleService.setTitle('Atwork | Forms');
    this.router.navigateByUrl('/panel/forms');
  }

  logout() {
    localStorage.clear();
    this.sheetRepo.clear();
    this.router.navigateByUrl('/');
  }

  goToHome() {
    this.focusArea = 'space';
    this.navService.goToDefaultSpace();
  }
}
