import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { GptConversationDto } from 'src/app/data/objects/gpt-conversation.dto';
import { RxjsService } from 'src/app/share/services/rxjs/rxjs.service';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/chat/chat.service';
import { CreateNewChatAiRequest } from 'src/app/data/request/create-new-chat-ai.request';

@Component({
  selector: 'app-nav-messaging',
  templateUrl: './nav-messaging.component.html',
  styleUrls: ['./nav-messaging.component.scss'],
})
export class NavMessagingComponent implements OnInit {
  @Input() showTitles: boolean = true;

  gptChats: GptConversationDto[] = [];

  constructor(
    private navService: NavigationService,
    private rxjs: RxjsService,
    private chatApi:ChatService,
    private router: Router,
  ) {}
  async ngOnInit() {
    this.gptChats = await this.navService.getMyGptConversation();
  }
  changeChat(chat: GptConversationDto) {
    this.rxjs.broadCastChatItem(chat);
  }
  async newChatAI() {
    let newReq = new CreateNewChatAiRequest();
    newReq.content = 'Hi';
    let res = await this.chatApi.createNewChatAI(newReq);
    this.gptChats.push(res.data);
    this.router.navigateByUrl(`/panel/chat/${res.data.id}`)
  }
}
