<div style="padding:4px ; height:100%; overflow-y:auto">
  <!-- <div class="form-title"> -->
  <!-- <icon icon="bi-ui-checks" [active]="true" size="xxlarge"></icon> -->
  <!-- <span>Forms</span> -->
  <!-- </div> -->
  <div class="form-item" *ngFor="let form of forms" routerLink="/panel/forms/{{form.code}}"
       [id]="form.code" routerLinkActive="form-item-active">
    <icon [icon]="form.icon" size="xlarge"></icon>
    @if (showTitles) {

      <span>
            {{ form.name }}
        </span>
    }
  </div>
</div>
