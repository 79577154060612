import { Injectable } from '@angular/core';
import { Role } from '../data/enums/role.enum';
import { EmployeeDto } from '../data/objects/employee.dto';
import { PermissionDto } from '../data/objects/permission.dto';
import { ShareDto } from '../data/objects/share.dto';
import { BasicsRepository } from '../data/repository/basics.repository';
import { SheetRepository } from '../data/repository/sheet.repository';
import { UtilService } from '../share/utils/util.service';
import { TreeItem } from './tree-item';

@Injectable({
  providedIn: 'root'
})
export class NavSharesService {
  shares: ShareDto;
  tree: TreeItem;
  itemCount: number;
  myEmployee: EmployeeDto;
  indentPx = 12;


  constructor(private baseRepo: BasicsRepository,
    private sheetRepo: SheetRepository,
    private utils: UtilService,


  ) { }

  async getSharesTree(activeSheetId) {
    const lang = localStorage.getItem('lang');

    this.shares = await this.sheetRepo.getShares();
    this.tree = {
      canDelete: false,
      canEdit: false,
      canShare: false,
      children: [],
      colapsed: true,
      icon: 'bi-share-fill',
      id: 0,
      level: 1,
      myRole: Role.VIEWER,
      name: lang=='fa'?'اشتراک از دیگران': 'Shared with me',
      type: 'folder',
      deleting: false,
      editable: false,
      renaming: false,
      indentPx: this.indentPx,
      permission: new PermissionDto()
    };

    this.shares.folders.forEach(folder => {
      let index = this.shares.folders.findIndex(f => f.id == folder.parentId);
      if (index == -1) {
        folder.parentId = 0;
      }
    });

    this.shares.items.forEach(item => {
      let index = this.shares.folders.findIndex(f => f.id == item.folderId);
      if (index == -1) {
        item.folderId = 0;
      }
    });


    this.itemCount = this.shares.folders.length + this.shares.items.length;
    this.myEmployee = await this.sheetRepo.getMyEmployee();
    await this.addChildren(this.tree,activeSheetId);
    return this.tree;
  }


  async addChildren(item: TreeItem,activeSheetId) {
    if (this.itemCount == 0) return;
    for (let i = 0; i < this.shares.folders.length; i++) {
      let search = this.shares.folders[i];
      if (item.id == search.parentId) {
        let role = await this.utils.myAccessRole(search.permission, this.myEmployee);
        let newItem: TreeItem = {
          colapsed: true, editable: !search.isDefault,
          children: [], icon: search.icon || 'bi-folder',
          id: search.id, name: search.name,
          type: 'folder', level: item.level + 1,
          indentPx: (item.level + 1) * this.indentPx,
          permission: search.permission,
          myRole: role,
          canDelete: this.utils.canDelete(role),
          canEdit: this.utils.canEdit(role),
          canShare: this.utils.canShare(role),
        };
        item.children.push(newItem);
        this.itemCount--;
        await this.addChildren(newItem,activeSheetId);
        item.colapsed = newItem.colapsed;
      }
    }
    for (let i = 0; i < this.shares.items.length; i++) {
      let search = this.shares.items[i];
      if (item.id == search.folderId) {

        if (search.type == 'SHEET') {
          let role = await this.utils.myAccessRole(search.permission, this.myEmployee);
          let newItem: TreeItem = {
            colapsed: false, editable: true,
            children: [], icon: search.icon,
            id: search.refId, name: search.name,
            type: 'sheet', level: item.level + 1,
            indentPx: (item.level + 1) * this.indentPx,
            permission: search.permission,
            myRole: role,
            canDelete: this.utils.canDelete(role),
            canEdit: this.utils.canEdit(role),
            canShare: this.utils.canShare(role),

          };
          item.children.push(newItem);
          if (newItem.id == activeSheetId) {
            item.colapsed = false;
          }
          this.itemCount--;
        } else {
          this.itemCount--;
        }
      }
    }
  }



}
