<div class="chat-holder">
    <div class="chat-item" [class.hide-title]="!showTitles" style="margin-top: 0.5rem;"
        routerLink="/panel/chat/notifications" routerLinkActive="chat-item-active">
        <icon icon="bi-bell" size="xlarge" [ngbTooltip]="!showTitles?'Notifications':''" container="body"></icon>
        <span [hidden]="!showTitles">
          {{'NOTIFICATIONS' | translate }}
        </span>
    </div>
    <hr style="background-color: white;">
    <div class="chat-item" [class.hide-title]="!showTitles" *ngFor="let chat of gptChats" routerLink="/panel/chat/{{chat.id}}"
        routerLinkActive="chat-item-active" (click)="changeChat(chat)">
        <icon icon="bi-robot" size="xlarge" [ngbTooltip]="!showTitles?chat.subject:''" container="body"></icon>
        <span [hidden]="!showTitles">
            {{chat.subject}}
        </span>
    </div>
<!--    <button class="btn text-light" (click)="newChatAI()">-->
<!--      <icon icon="bi-plus" size="normal" container="body"></icon>-->
<!--      <span style="vertical-align: middle;">-->
<!--        {{'NEW_CHAT' | translate }}-->
<!--      </span>-->
<!--    </button>-->
</div>
