<div class="nav-container" [ngClass]="isCollapse ? 'nav-collapsed' : 'nav-expanded'">
  <div class="nav-content">
    <div class="top-holder" [ngClass]="isCollapse ? 'top-collapsed' : ''">
      <atw-image
        (click)="goToHome()"
        type="atwork" style="width:36px;height:36px;cursor:pointer;margin-inline-start: 8px"></atw-image>

      <icon
        icon="bi-person-workspace"
        class="top-item"
        [active]="focusArea == 'space'"
        size="xlarge"
        ngbTooltip="{{'WORKSPACE' | translate}}"
        placement="auto"
        container="body"
        color="white"
        (click)="backToSpace()"
        [ngClass]="focusArea == 'space' ? 'top-item-active' : ''"
      ></icon>

      <icon
        icon="bi-ui-checks"
        [active]="focusArea == 'forms'"
        class="top-item"
        size="xlarge"
        ngbTooltip="{{'FORMS' | translate}}"
        container="body"
        placement="auto"
        color="white"
        (click)="forms()"
        [ngClass]="focusArea == 'forms' ? 'top-item-active' : ''"
      ></icon>

      <icon
        *ngIf="isOwner"
        icon="bi-gear"
        [active]="focusArea == 'setting'"
        class="top-item"
        size="xlarge"
        (click)="administration()"
        ngbTooltip="{{'ADMINISTRATION' | translate }}"
        container="body"
        placement="auto"
        color="white"
        [ngClass]="focusArea == 'setting' ? 'top-item-active' : ''"
      ></icon>

      <icon
        icon="bi-chat-dots"
        [badge]="chatBadge"
        [active]="focusArea == 'chat'"
        class="top-item"
        size="xlarge"
        (click)="messages()"
        ngbTooltip="{{'INBOX' | translate }}"
        container="body"
        placement="auto"
        color="white"
        routerLink="/panel/chat"
        routerLinkActive="top-item-active"
        [ngClass]="focusArea == 'chat' ? 'top-item-active' : ''"
      ></icon>
    </div>

    <app-nav-space [showTitles]="!isCollapse" [hidden]="focusArea != 'space'"></app-nav-space>
    <app-nav-messaging
      [showTitles]="!isCollapse"
      [hidden]="focusArea != 'chat'"
    ></app-nav-messaging>
    <app-nav-setting [showTitles]="!isCollapse" [hidden]="focusArea != 'setting'"></app-nav-setting>
    <app-nav-forms
      [showTitles]="!isCollapse"
      [hidden]="focusArea != 'forms'"
      [refresh]="focusArea == 'forms'"
    ></app-nav-forms>
  </div>

  <div
    class="nav-anchor"
    (click)="onToggleCollapseClick()"
    [ngClass]="isCollapse ? 'anchor-collapsed' : 'anchor-expanded'"
  >
    <i class="anchor-left bi bi-chevron-double-left"></i>
    <span class="anchor-pillar"></span>
    <i class="anchor-right bi bi-chevron-double-right"></i>
  </div>
</div>
