@for (item of folder.children; track item.type + item.id; let item_index = $index) {

  <div class="tree" [class.height-36px]="item.type=='sheet' || item.type =='page'"
       [ngClass]="isColapsed?'colapsed':''">

    <!-- folder section -->
    @if (item.type == 'folder') {
      <div>
        <!-- current folder -->
        <div #dropdown="ngbDropdown" ngbDropdown container="body" placement="bottom-end">
          <div (contextmenu)="openContextMenu(item,dropdown)" ngbDropdownAnchor class="folder"
               [ngClass]="!showTitles?'nav-collapsed-folder':''"
               [style.padding-inline-start]="showTitles?(item.indentPx)+'px':''"
               (click)="item.colapsed=!item.colapsed">

            <div class="item-left">
              @if (item.indentPx > indentPx && showTitles) {
                <div class="divider"></div>
              }
              <i class="bi toggle-icon naviblue-light-9"
                 [ngClass]="{'toggle-expanded':!item.colapsed && dir === 'ltr','toggle-expanded-rtl':!item.colapsed && dir === 'rtl','bi-caret-right-fill':dir === 'ltr' ,'bi-caret-left-fill':dir==='rtl'}"></i>

              <icon color="naviblue-light-9" [(icon)]="item.icon" size="xlarge"
                    [pickable]="item.renaming!!"
                    class="item-icon" [ngbTooltip]="!showTitles?item.name:''"
                    container="body"></icon>
              <span class="item-name naviblue-light-9" [style.max-width.px]="150-item.indentPx"
                    [class.bold-title]="!item.colapsed"
                    [hidden]="item.renaming || !showTitles">{{ item.name }}
                    </span>
              <div class="rename-holder" [hidden]="!item.renaming">
                <input class="rename-input" type="text" (keyup.esc)="item.renaming = false"
                       (keyup.enter)="onRenameSubmit(item)" (click)="$event.stopPropagation()"
                       [(ngModel)]="item.name">
                <icon icon="bi-check-lg" size="xlarge" color="green" class="h-100"
                      (click)="onRenameSubmit(item);$event.stopPropagation()"></icon>
              </div>

            </div>

            <div class="item-right"
                 [hidden]="!showTitles || item.renaming || !(item.editable ?? true)">
              <div class="d-flex" style="padding: 5px; column-gap: 8px">
                <icon
                  *ngIf="item.canEdit"
                  icon="bi-journal-plus"
                  size="large"
                  color="naviblue-lighter"
                  ngbTooltip="{{'ADD_NEW_WORKSHEET' | translate }}"
                  placement="bottom-end"
                  [openDelay]="500"
                  (click)="onCreateSheetClick(item); $event.stopPropagation()"
                ></icon>

                <icon
                  *ngIf="item.canEdit"
                  icon="bi-folder-plus"
                  size="large"
                  color="naviblue-lighter"
                  ngbTooltip="{{'ADD_SUB_FOLDER' | translate }}"
                  placement="bottom-end"
                  [openDelay]="500"
                  (click)="onCreateFolderClick(item); $event.stopPropagation()"
                ></icon>

                <icon
                  *ngIf="item.canEdit"
                  icon="bi-three-dots-vertical"
                  size="large"
                  color="naviblue-lighter"
                  (click)="dropdown.open(); $event.stopPropagation()"
                ></icon>
              </div>
            </div>
          </div>
          <div ngbDropdownMenu>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="onCreateTaskSheetClick(item); dropdown.close()"
            >
              <icon icon="bi-list-task" color="naviblue"></icon>
              {{ 'ADD_NEW_TASKSHEET' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="onImportCsvClick(item); dropdown.close()"
            >
              <icon icon="bi-file-earmark-arrow-down" color="naviblue"></icon>
              {{ 'IMPORT_CSV' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canEdit"
              class="dropdownitem"
              (click)="item.renaming = true; dropdown.close()"
            >
              <icon icon="bi-pencil" color="naviblue"></icon>
              {{ 'RENAME' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canShare"
              class="dropdownitem"
              (click)="showPermissionDialog(item); dropdown.close()"
            >
              <icon icon="bi-shield-lock" color="naviblue"></icon>
              {{ 'PERMISSIONS' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canShare && environment.exportDomains.includes(domain||'')"
              class="dropdownitem"
              (click)="showExportDialog(item); dropdown.close()"
            >
              <icon icon="bi-box-arrow-up" color="naviblue"></icon>
              {{ 'EXPORT' | translate }}
            </div>
            <div
              *ngIf="!item.deleting && item.canDelete"
              class="dropdownitem"
              (click)="item.deleting = true"
            >
              <icon icon="bi-folder-x" color="naviblue"></icon>
              {{ 'delete' | translate }}
            </div>
            <div *ngIf="item.deleting" class="confirm-delete-holder">
              <div class="confirm-title">{{ 'areYouSure?' | translate }}</div>
              <div class="confirm-delete-button-holder">
            <span
              class="confirm-yes"
              (click)="item.deleting = false; dropdown.close(); deleteItem(item, item_index)"
            >{{ 'delete' | translate }}</span>
                <span class="confirm-no"
                      (click)="item.deleting = false; dropdown.close()">{{ 'cancel' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- nested folders -->
        <app-tree-folder
          *ngIf="item.children.length > 0"
          [folder]="item"
          [currentItem]="currentItem"
          [isColapsed]="item.colapsed"
          [showTitles]="showTitles"
          [currentSpaceId]="currentSpaceId"
        ></app-tree-folder>
      </div>
    }

    <!-- items section -->
    @if (item.type == 'sheet' || item.type == 'page') {
      <div
        style="width: 100%;"
        #dropdown="ngbDropdown"
        container="body"
        ngbDropdown
        placement="bottom-end"
        class="d-inline-block"
      >
        <div
          #itemHolder="routerLinkActive"
          ngbDropdownAnchor
          (contextmenu)="openContextMenu(item, dropdown)"
          class="item"
          id="item_{{item.id}}"
          [ngClass]="!showTitles ? 'nav-collapsed-item' : ''"
          [style.padding-inline-start]="showTitles ? item.indentPx + 'px' : ''"
          [class.active]="item.id==currentItem"
          routerLinkActive="active"
          [routerLink]="item.type=='sheet'?'/panel/space/'+currentSpaceId +'/sheet/'+ item.id :'/panel/space/'+currentSpaceId+'/page/'+item.id"
        >
          <div class="item-left">
            @if (item.indentPx > indentPx && showTitles) {
<!--              <div class="divider" [style.opacity]="itemHolder.isActive?0:1"></div>-->
              <div class="divider" [style.margin-inline-end]="itemHolder.isActive?0:'8px'" ></div>
              @if(itemHolder.isActive){
              <div class="divider-active">●</div>
              }
            }
            <icon
              color="white"
              class="item-icon"
              [pickable]="item.renaming!!"
              size="xlarge"
              [(icon)]="item.icon"
              [ngbTooltip]="!showTitles ? item.name : ''"
              container="body"
              (click)="$event.stopPropagation();"
            ></icon>

            <span
              class="item-name"
              [style.max-width.px]="220 - item.indentPx"
              [hidden]="item.renaming || !showTitles"
            >{{ item.name }}</span
            >
            <div class="rename-holder" [hidden]="!item.renaming">
              <input
                class="rename-input"
                type="text"
                (keyup.esc)="item.renaming = false"
                (keyup.enter)="onRenameSubmit(item)"
                (click)="$event.stopPropagation()"
                [(ngModel)]="item.name"
              />
              <icon
                icon="bi-check-lg"
                size="xlarge"
                color="green"
                class="h-100"
                (click)="onRenameSubmit(item); $event.stopPropagation()"
              ></icon>
            </div>
          </div>
          <div class="item-right" [hidden]="!showTitles || item.renaming">
            <icon
              icon="bi-three-dots-vertical"
              color="naviblue-lighter"
              size="large"
              *ngIf="item.canEdit"
              (click)="dropdown.open(); $event.stopPropagation()"
            ></icon>
          </div>
        </div>
        <div *ngIf="item.editable" ngbDropdownMenu>
          <div
            *ngIf="!item.deleting && item.canEdit"
            class="dropdownitem"
            (click)="item.renaming = true; dropdown.close()"
          >
            <icon icon="bi-pencil" color="naviblue"></icon>
            {{ 'RENAME' | translate }}
          </div>
          <div
            *ngIf="!item.deleting && item.canShare"
            class="dropdownitem"
            (click)="showPermissionDialog(item); dropdown.close()"
          >
            <icon icon="bi-shield-lock" color="naviblue"></icon>
            {{ 'PERMISSIONS' | translate }}
          </div>
          <div
            *ngIf="!item.deleting && item.canShare && environment.exportDomains.includes(domain||'')"
            class="dropdownitem"
            (click)="showExportDialog(item); dropdown.close()"
          >
            <icon icon="bi-box-arrow-up" color="naviblue"></icon>
            {{ 'EXPORT' | translate }}
          </div>
          <div
            *ngIf="!item.deleting && item.canDelete"
            class="dropdownitem"
            (click)="item.deleting = true"
          >
            <icon icon="bi-journal-x" color="naviblue"></icon>
            {{ 'delete' | translate }}
          </div>
          <div *ngIf="item.deleting" class="confirm-delete-holder">
            <div class="confirm-title">{{ 'areYouSure?' | translate }}</div>
            <div class="confirm-delete-button-holder">
          <span
            class="confirm-yes"
            (click)="item.deleting = false; dropdown.close(); deleteItem(item, item_index)"
          >{{ 'delete' | translate }}</span
          >
              <span class="confirm-no"
                    (click)="item.deleting = false; dropdown.close()">{{ 'cancel' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    }

  </div>
}
